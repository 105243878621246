import { CognitoService } from "@root/services/cognitoService";
import { useEffect, useState } from "react";
import { Hub } from "aws-amplify/utils";
import { useStore } from "@nanostores/react";
import { userPersonalDataStore, userRootDataStore } from "@root/framework/stores/user-store";
import type { UserPersonalDataType, UserRootDataType } from "@root/types/user-types";
import { navigate } from "astro/virtual-modules/transitions-router.js";
import { accessTokenStore } from "@root/framework/stores/app-store";
import { initFlowbite } from "flowbite";

const UserMenu = () => {
    const cognitoService = new CognitoService();

    const $userRootData = useStore(userRootDataStore);
    const $userPersonalData = useStore(userPersonalDataStore);

    const [loading, setLoading] = useState(false);
    const [userMenuVisible, setUserMenuVisible] = useState(true);

    useEffect(() => {
        initFlowbite();
    }, [])

    useEffect(() => {
        const unsubscribe = Hub.listen("auth", ({ payload }) => {
            switch (payload.event) {
                case "signInWithRedirect":
                    cognitoService.getCurrentSession().then((sessionData) => {
                        setUserData(sessionData);
                        setLoading(false);
                    });
                    break;
                case "signInWithRedirect_failure":
                    console.error("signInWithRedirect_failure:", payload);
                    break;
                case "signedOut":
                    clearUserData();
                    navigate("/");
                    break;
            }
        });

        return unsubscribe;
    }, []);

    const setUserData = async (currentSessionData: any) => {
        const personalData = currentSessionData.tokens.idToken.payload["data:personal"] as UserPersonalDataType;
        if (personalData) {
            userPersonalDataStore.set(personalData);
        }
        else {
            userPersonalDataStore.set({
                fullName: currentSessionData.tokens.idToken.payload["name"],
                shortName: currentSessionData.tokens.idToken.payload["given_name"]
            });
        }

        const userRootData = currentSessionData.tokens.idToken.payload["root"] as UserRootDataType;

        if (userRootData) {
            userRootDataStore.set(userRootData);
        }
        else {
            userRootDataStore.set({
                id: currentSessionData.tokens.idToken.payload["cognito:username"],
                picture: currentSessionData.tokens.idToken.payload["picture"],
                groups: [],
                globalAreasIds: [],
                lastUpdate: undefined,
                enabled: true
            });
        }
        const accessToken = currentSessionData.tokens.accessToken.toString();
        accessTokenStore.set(accessToken);
    };

    useEffect(() => {
        setUserMenuVisible(!location?.pathname.includes('/calls/'));
    })

    useEffect(() => {
        if ($userRootData?.id === undefined) {
            setLoading(true);
            cognitoService.getCurrentSession()
                .then((sessionData) => {
                    if (!sessionData.tokens?.idToken) {
                        location.assign("/auth/sign-in")
                        return;
                    }
                    setUserData(sessionData);
                    setLoading(false);
                });
        }
    }, []);

    const clearUserData = async () => {
        userRootDataStore.set(undefined);
        userPersonalDataStore.set(undefined);
    }

    return (
        <>
            <div className={`absolute top-0 left-0 w-screen h-screen ${loading ? "flex" : "hidden"} items-center justify-center bg-black bg-opacity-95 z-50 text-white`}>
                <div>
                    <svg aria-hidden="true" className="inline h-14 text-gray-200 animate-spin dark:text-gray-600 fill-[#e72b81]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span className="sr-only">Cargando...</span>
                </div>
            </div>
            <div data-dial-init className={`fixed end-10 bottom-16 group ${userMenuVisible ? "fixed" : "hidden"}`}>
                <div id="user-dial-menu" className="flex-col items-center hidden mb-4 space-y-2">
                    <button type="button" data-tooltip-target="tooltip-picture" data-tooltip-placement="left" className="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
                        <img src={$userRootData?.picture} alt="user-picture"
                            className="p-1 rounded-full" />
                        <span className="sr-only">Foto usuario</span>
                    </button>
                    <div id="tooltip-picture" role="tooltip" className="absolute z-50 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        {$userPersonalData?.shortName}
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <button type="button" data-tooltip-target="tooltip-sign-out" data-tooltip-placement="left"
                        onClick={cognitoService.signOut}
                        className="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 dark:hover:text-white shadow-sm dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 -960 960 960" fill="currentColor">
                            <path d="M206.78-100.78q-44.3 0-75.15-30.85-30.85-30.85-30.85-75.15v-546.44q0-44.3 30.85-75.15 30.85-30.85 75.15-30.85h277.74v106H206.78v546.44h277.74v106H206.78Zm425.87-152.09L559-328.39 657.61-427H355.48v-106h302.13L559-631.61l73.65-75.52L859.22-480 632.65-252.87Z" />
                        </svg>
                        <span className="sr-only">Salir</span>
                    </button>
                    <div id="tooltip-sign-out" role="tooltip" className="absolute z-10 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        Salir
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                </div>
                <div className="bg-[#EA96BF80] p-1 w-full h-full rounded-full flex justify-center items-center">
                    <button type="button" data-dial-toggle="user-dial-menu" aria-controls="user-dial-menu" aria-expanded="false"
                        className="flex  items-center justify-center text-white bg-[#EA96BFFF] rounded-full w-14 h-14  focus:ring-4 focus:ring-blue-300 focus:outline-none">
                        <svg className="w-5 h-5 transition-transform group-hover:block hidden group-hover:rotate-45" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                        </svg>
                        <img src={$userRootData?.picture} alt="user-picture"
                            className=" rounded-full group-hover:hidden" />
                        <span className="sr-only">Abrir menú de acciones</span>
                    </button>
                </div>
            </div>

        </>
    )
}

export default UserMenu;